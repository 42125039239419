body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 리뷰 모바일 상세페이지 스타일 안잡히는 것 위한 임시 스타일 적용 */
@media (max-width: 599px) {
  .ReviewList-listWithDrawer-13 {
    margin-right: 0 !important;
  }
}
